<template>
  <div class="grafik-contract">
    <div class="container fluid">
      <div class="row aic">
        <div class="col-auto">
          <ul class="breadcumb">
            <li>
              <router-link :to="{ name: 'profile.contracts' }">
                {{ $localize('profile')['contract']['contracts'] }}
              </router-link>
            </li>
            <img src="@/assets/profile_assets/chevron-right.svg" alt="" />
            <li>
              <router-link :to="{ name: 'profile.contract', params: { id: $route.params.id } }">
                {{ $localize('profile')['contract']['number'] }} №{{contracts.contract_id}}
              </router-link>
            </li>
            <img src="@/assets/profile_assets/chevron-right.svg" alt="" />
            <li>
              <a href="javascript:" class="active">{{ $localize('profile')['history']['paymentGraphic'] }}</a>
            </li>
          </ul>
        </div>

        <div class="col-auto">
          <router-link :to="{ name: 'profile.contract', params: { id: $route.params.id } }" class="btn-back">
            {{ $localize('button')['back'] }}
          </router-link>
        </div>
      </div>

      <h1>{{ $localize('profile')['history']['paymentGraphic'] }}</h1>

      <div class="row">
        <div class="col-xl-9 col-ml-9 col-12">

          <loading v-if="loading" size="lg" />

          <div class="card-table" v-else>

            <div class="table-head">
              <h3>{{ $localize('profile')['history']['paymentDate'] }}</h3>
              <h3>{{ $localize('profile')['history']['amountToPay'] }}</h3>
            </div>

            <div class="table-body">
              <div class="table-item" v-for="(n,i) in schedule_list" :key="i">

                <div class="left">
                  <h4>{{ $t('month', { number: i + 1, hyphen: '-' }) }}</h4>
                  <p>{{ n.payment_date }}</p>
                </div>

                <div class="rigth">

                  <span v-if="n.status === 1">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M12.0866 15.6763L15.2506 18.839L20.9266 13.1616M26.6639 15.999C26.6639 21.8885 21.8895 26.663 15.9999 26.663C10.1104 26.663 5.33594 21.8885 5.33594 15.999C5.33594 10.1094 10.1104 5.33496 15.9999 5.33496C21.8895 5.33496 26.6639 10.1094 26.6639 15.999Z"
                        stroke="#53DB8C"
                        stroke-miterlimit="10"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>

                  <span v-else>{{ $t('currency', { price: $numberFormat(n.balance) }) }}</span>

                </div>

              </div>
            </div>

          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  data: () => ({
    contracts: {},
    schedule_list: [],
    loading: false,
  }),
  created() {
    this.loading = true;
    this.$axios.post('/buyer/contract',
      { contract_id: this.$route.params.id })
      .then((response) => {
        this.contracts = response.data.contracts;
        this.schedule_list = response.data.schedule_list;
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
  .grafik-contract {
    padding: 48px 44px;
    min-height: calc(100vh - 96px);
    background: $grey;
    width: 100%;

    h1 {
      font-weight: 900;
      font-size: 32px;
      line-height: 40px;
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 16px;
      margin-top: 16px;
    }

    .btn-back {
      background: $main;
      box-shadow: -12px 12px 30px rgba(102, 16, 245, 0.25);
      border-radius: 8px;
      width: auto;
      padding: 8px 16px;
      font-weight: 600;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      border: none;
      transition: 0.2s;

      &:hover {
        cursor: pointer;
        background: $main-light;
      }
    }

    .breadcumb {
      display: flex;
      align-items: center;

      a {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        display: flex;
        align-items: center;
        color: $black;
        opacity: 0.3;

        &.active {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          display: flex;
          align-items: center;
          color: $main;
          opacity: 1;
        }
      }
    }

    .aic {
      align-items: center;
      justify-content: space-between;
    }

    .card-table {
      background: $white;
      border-radius: 8px;
      padding: 24px 0;

      .table-head {
        padding: 0 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        h3 {
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.38px;
          color: $black;
        }
      }

      .table-body {
        .table-item {
          padding: 9px 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.04);

          &:last-child {
            box-shadow: unset;
          }

          &:nth-of-type(odd) {
            background-color: #FCFCFC;
          }

          h4 {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: $black;
            margin-bottom: 8px;
          }

          p {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: $black;
          }

          span {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: $main;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .grafik-contract {
      padding: 27px 0px;
      min-height: calc(100vh - 48px);
      width: auto;
      margin-left: -16px;
      margin-right: -16px;
      background: $white;

      h1 {
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 8px;
      }

      .btn-back {
        display: none;
      }

      .card-table {
        padding-top: 0px;

        .table-body {
          .table-item {
            padding: 9px 0px;
          }
        }
      }

      .card-table .table-head {
        padding: 9px 0px;
      }
    }
    @media (max-width: 400px) {
      .grafik-contract {
        .breadcumb a.active,
        .breadcumb a {
          font-size: 11px;
        }
      }
    }
  }
</style>
